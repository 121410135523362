/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Custom CSS utils */
@import 'src/styles/index.scss';

@import '@ng-select/ng-select/themes/default.theme.css';
@import "swiper/css";
@import "swiper/css/pagination";
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';


@font-face {
  font-family: 'Metropolis';
  font-weight: normal;
  src: local('Metropolis'), url(/assets/fonts/Metropolis-Regular.ttf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 700;
  src: local('Metropolis'), url(/assets/fonts/Metropolis-Bold.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  src: local('Metropolis'), url(/assets/fonts/Metropolis-SemiBold.otf) format('truetype');
  font-display: swap
}

/** define default style for ion components **/
ion-text {
  @extend .op-body;
}
ion-button ion-text {
  margin: 0;
}

ion-label {
  white-space: normal !important;
}

:root {
  ion-header {
    ion-toolbar {
      --ion-color-base: var(--ion-color-white) !important;
    }
  }

  ion-popover {
    &.tooltip-pc{
      --width:500px;
    }
  }

  ion-modal {
    --border-radius: 10px;

    &.op-card-modal,
    &.op-mobile-card-modal {
      --border-radius: 20px;
      --height: auto;

      .inner-content {
        max-height: 88vh;
        overflow: auto;
      }
    }

    &.op-card-modal {
      --max-width: 34.75rem;
    }

    &.op-card-modal--wide {
      --max-width: 42.5rem;
    }

    &.op-mobile-card-modal {
      padding: 1.25rem 1rem;
    }

    &.op-setup-password-modal {
      --max-width: 34.5rem;
    }

    &.op-delete-account-modal {
      --height: 100%;
      --max-height: 24rem;
      --max-width: 32.75rem;

      ion-content {
        overflow: auto;
        --overflow: hidden;
        letter-spacing: 0.5px;
        --color: var(--ion-color-gray-500);
      }
    }
    &.op-delete-account-modal-without-password {
      @extend .op-delete-account-modal;
      --max-height: 18rem;
    }

    &.op-change-password-modal {
      --height: 100%;
      --max-height: 30.25rem;
      --max-width: 32.75rem;

      ion-content {
        overflow: auto;
        --overflow: hidden;
        letter-spacing: 0.5px;
        --color: var(--ion-color-gray-500);
      }
    }
  }

  ion-accordion {
    border-radius: 8px;
    margin: 1rem 0;
    &.yellow {
      border: 1px solid #f3e4c4 !important;
    }
    &.blue{
      border: 1px solid #39567E !important;
    }
    &.blue-0{
      border: 0px;
      border-radius: 0px !important;
    }
    ion-item {
      --border-color: transparent;
      --border-radius: 0px;


      &.blue-title{
        ion-label {
          color: #39567E !important;
        }
      }
      &.blue-0-title{
        --ion-item-background: #f9f9f9;
        ion-label {
          color: #39567E !important;
        }
      }

      ion-label,
      ion-text {
        padding: 1rem;
        text-align: left;
      }

      ion-label {
        margin: 0px;
        font-size: 20px;
        font-weight: 600;
        white-space: unset;
      }
    }

    &.accordion-expanded {
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      ion-item {
        &.blue-title,&.blue-0-title{
          --background: #37597C !important;
          ion-label {
            color: #FFFFFF !important;
          }
          .ion-accordion-toggle-icon {
            color: #FFFFFF !important;
          }
        }
        &.blue-content,&.blue-0-content{
          --background: #F3F5F7 !important;
          ion-text {
            color: #000000 !important;
          }
        }
      }

    }
  }

}
