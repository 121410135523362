@import 'src/styles/breakpoints';
@mixin typography {
  margin-top: 0;
  font-weight: 400;
}

@mixin h1 {
  font-size: 2.8rem;
  line-height: 3rem;
  margin-bottom: 1rem;
}

@mixin h2 {
  font-size: 2.8rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  letter-spacing: -0.03em;
}

@mixin h3 {
  font-size: 2.8rem;
  line-height: 3rem;
  margin-bottom: 1rem;
}

@mixin h4 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

@mixin h5 {
  font-size: 1.875rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
}

@mixin h6 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
}

@mixin h7 {
  font-size: 1.35rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.op-text-secondary {
  color: rgba(0, 0, 0, 0.8);
}
.op-text-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.op-text-link {
  cursor: pointer;
}

@mixin subheading {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

@mixin body-large {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
}

@mixin body {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  -webkit-user-select: text;
  user-select: text;
}

@mixin body-small {
  font-size: 0.85rem;
  line-height: 1.1rem;
  margin-bottom: 0.3rem;
}

.op h1,
.op-h1 {
  @include typography;
  @include h3;
}

.op h2,
.op-h2 {
  @include typography;
  @include h4;
}

.op h3,
.op-h3 {
  @include typography;
  @include h5;
}

.op h4,
.op-h4 {
  @include typography;
  @include h6;
}

.op h5,
.op-h5 {
  @include h7;
  @include typography;
  font-weight: bold;
}

.op h6,
.op-h6 {
  @include body;
  @include typography;
  font-weight: bold;
}

.op h7,
.op-h7 {
  @include body-small;
  @include typography;
  font-weight: bold;
}

.op-subheading {
  @include typography;
  @include body;
}

.op-body-large {
  @include typography;
  @include body;
}

.op-body {
  @include typography;
  @include body;
}

.op-body-small {
  @include typography;
  @include body-small;
}

@media (min-width: map-get($breakpoints, ipad)) {
  .op h1,
  .op-h1 {
    @include h1;
  }

  .op h2,
  .op-h2 {
    @include h2;
  }

  .op h3,
  .op-h3 {
    @include h3;
  }

  .op h4,
  .op-h4 {
    @include h4;
  }

  .op h5,
  .op-h5 {
    @include h5;
  }

  .op h6,
  .op-h6 {
    @include h6;
  }

  .op-subheading {
    @include typography;
    @include subheading;
  }

  .op-body-large {
    @include typography;
    @include body-large;
  }

  .op-body-small {
    @include typography;
    @include body-small;
  }
}

.op-container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.op-privacy-policy-link {
  color: var(--ion-color-blue);
  text-decoration: unset;

  &:hover {
    text-decoration: underline;
  }
}
.privacyPolicy {
  .op-h3 {
    font-size: 1.5rem;
  }
  .op-h5 {
    font-size: 1.25rem;
  }
  a {
    color: var(--ion-color-blue);
    text-decoration: unset;

    &:hover {
      text-decoration: underline;
    }
  }
}

.custom-aqua-dashed-block {
  border: 1px dashed #55B2CB;
  border-style: dashed !important;
  background-color: #ffffff;
  height: 100%;
  min-height: 240px;
}

.custom-orange-dashed-block {
  border: 1px dashed #FFAE88;
  background: rgba(255, 174, 136, 0.1);
  height: 100%;
  min-height: 265px;
}

.op-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.op-tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 1rem;
  position: absolute;
  z-index: 999;
  bottom: 125%;
  left: 20%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
  &.top-right {
    left: 0% !important;
    margin-left: 0px !important;;
    &::after{
      top: 100%;
      left: 3% !important;;
    }
  }
  &.top-left {
    left: 0% !important;
    margin-left: -285px !important;;
    &::after{
      top: 100%;
      left: 97% !important;;
    }
  }
}


.op-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.op-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: map-get($breakpoints, ipad)) {
  .custom-section-header {
    float: none !important;
    margin: auto;
    width: 95% !important;
    h3{
      text-align: center;
    }
  }

  .op-tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 999;
    bottom: 125%;
    left: 20%;
    margin-left: -110px;
    opacity: 0;
    transition: opacity 0.3s;
    &.top-right {
      left: 0% !important;
      margin-left: 0px !important;;
      &::after{
        top: 100%;
        left: 5% !important;;
      }
    }
    &.top-left {
      left: 0% !important;
      margin-left: -230px !important;;
      &::after{
        top: 100%;
        left: 95% !important;;
      }
    }
  }
}



