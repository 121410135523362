// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** sizes custom variable **/
  --image-content-img-size: 150px;
  --image-content-img-size-xl: 250px;
  --video-content-video-size-width: 343px;
  --video-content-video-size-height: 194px;
  --video-content-video-size-xl-width: 460px;
  --video-content-video-size-xl-height: 260px;

  /** theme custom variables **/
  --ion-theme-font: 'Metropolis', sans-serif;
  --ion-offset: 16px;

  /** padding **/
  --ion-padding-hr-xs-minor: 0.75rem;
  --ion-padding-hr-xs: 1rem;
  --ion-padding-hr-xs-great: 1.5rem;
  --ion-padding-hr-sm: 2.5rem;
  --ion-padding-hr-md: 3.5rem;
  --ion-padding-hr-lg: 3rem;
  --ion-padding-vr-sm: 1rem;
  --ion-padding-vr-md: 3rem;
  --ion-padding-vr-lg: 9.25rem;
  --ion-padding-vr-xlg: 12.25rem;

  /** brand-blue **/
  --ion-color-blue: #37597c;
  --ion-color-mid-blue: #55b2cb;
  --ion-color-blue-80: #607a96;
  --ion-color-blue-60: #869bb0;
  --ion-color-blue-40: #afbdca;
  --ion-color-blue-20: #d7dee5;
  --ion-color-blue-10: #ebeef2;
  --ion-color-blue-rgb: 55, 89, 124;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #304e6d;
  --ion-color-blue-tint: #4b6a89;
  --ion-color-blue-5: rgba(55, 89, 124, 0.05);
  --ion-color-blue-gray-600: #475569;

  /** brand peach **/
  --ion-color-peach: #ffb694;

  --ion-color-peach-80: #ffbea0;
  --ion-color-peach-60: #ffceb7;
  --ion-color-peach-40: #ffdfcf;
  --ion-color-peach-20: #ffefe7;

  --ion-color-peach-rgb: 255, 182, 148;
  --ion-color-peach-contrast: #000000;
  --ion-color-peach-contrast-rgb: 0, 0, 0;
  --ion-color-peach-shade: #e0a082;
  --ion-color-peach-tint: #ffbd9f;

  /** brand aqua **/
  --ion-color-aqua: #55b2cb;
  --ion-color-aqua-80: #77c1d5;
  --ion-color-aqua-60: #99d1e0;
  --ion-color-aqua-40: #bbdfe9;
  --ion-color-aqua-20: #def0f5;
  --ion-color-aqua-rgb: 85, 178, 203;
  --ion-color-aqua-contrast: #000000;
  --ion-color-aqua-contrast-rgb: 0, 0, 0;
  --ion-color-aqua-shade: #4b9db3;
  --ion-color-aqua-tint: #66bad0;

  /** brand cream **/
  --ion-color-cream: #f3e4c4;
  --ion-color-cream-80: #f3e4c4;
  --ion-color-cream-60: #f8efdc;
  --ion-color-cream-40: #faf4e8;
  --ion-color-cream-20: #fdfaf3;
  --ion-color-cream-rgb: 243, 228, 196;
  --ion-color-cream-contrast: #000000;
  --ion-color-cream-contrast-rgb: 0, 0, 0;
  --ion-color-cream-shade: #d6c9ac;
  --ion-color-cream-tint: #f4e7ca;

  /** brand black **/
  --ion-color-black: #000000;
  --ion-color-black-80: #333333;
  --ion-color-black-60: #666666;
  --ion-color-black-50: #808080;
  --ion-color-black-40: #999999;
  --ion-color-black-20: #cccccc;
  --ion-color-black-10: #e6e6e6;
  --ion-color-black-border-rgb: rgba(0, 0, 0, 0.2);
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** brand white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
  /** brand gray **/
  --ion-color-gray-500: #667085;
  --ion-color-gray-800: #1d2939;
  --ion-color-gray-900: #101828;

  /** primary **/
  --ion-color-primary: #3e59e3;
  --ion-color-primary-rgb: 62, 89, 227;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #374ec8;
  --ion-color-primary-tint: #516ae6;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #dc2626;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #e0e5ec;
  --ion-color-light-rgb: 224, 229, 236;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c5cad0;
  --ion-color-light-tint: #e3e8ee;
}

/*
* Dark Colors
* -------------------------------------------
*/

body.dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  /*
    * iOS Dark Theme
    * -------------------------------------------
    */

  .ios & {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }

  /*
    * Material Design Dark Theme
    * -------------------------------------------
    */

  .md & {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-item-background: #1e1e1e;
  }
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-white-blue {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-blue);
  --ion-color-contrast-rgb: var(--ion-color-blue-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
  --border-style: solid;
  --border-width: 1px;
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-blue-20 {
  --ion-color-blue-20-rgb: 215, 222, 229;
  --ion-color-blue-20-contrast: #000000;
  --ion-color-blue-20-contrast-rgb: 0, 0, 0;
  --ion-color-blue-20-shade: #bdc3ca;
  --ion-color-blue-20-tint: #dbe1e8;

  --ion-color-base: var(--ion-color-blue-20);
  --ion-color-base-rgb: var(--ion-color-blue-20-rgb);
  --ion-color-contrast: var(--ion-color-blue-20-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-20-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-20-shade);
  --ion-color-tint: var(--ion-color-blue-20-tint);
}

.ion-color-aqua {
  --ion-color-base: var(--ion-color-aqua);
  --ion-color-base-rgb: var(--ion-color-aqua-rgb);
  --ion-color-contrast: var(--ion-color-aqua-contrast);
  --ion-color-contrast-rgb: var(--ion-color-aqua-contrast-rgb);
  --ion-color-shade: var(--ion-color-aqua-shade);
  --ion-color-tint: var(--ion-color-aqua-tint);
}

.ion-color-aqua-white {
  --ion-color-base: var(--ion-color-aqua);
  --ion-color-base-rgb: var(--ion-color-aqua-rgb);
  --ion-color-contrast: #FFFFFF;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: var(--ion-color-aqua-shade);
  --ion-color-tint: var(--ion-color-aqua-tint);
}

.ion-color-lightaqua {
  --ion-color-lightaqua: #def0f5;
  --ion-color-lightaqua-rgb: 85, 178, 203;
  --ion-color-lightaqua-contrast: #37597C;
  --ion-color-lightaqua-contrast-rgb: 0, 0, 0;
  --ion-color-lightaqua-shade: #4b9db3;
  --ion-color-lightaqua-tint: #66bad0;

  --ion-color-base: var(--ion-color-lightaqua);
  --ion-color-base-rgb: var(--ion-color-lightaqua-rgb);
  --ion-color-contrast: var(--ion-color-lightaqua-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightaqua-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightaqua-shade);
  --ion-color-tint: var(--ion-color-lightaqua-tint);
}

.ion-color-aqua-20 {
  --ion-color-aqua-20-rgb: 222, 240, 245;
  --ion-color-aqua-20-contrast: #000000;
  --ion-color-aqua-20-contrast-rgb: 0, 0, 0;
  --ion-color-aqua-20-shade: #c3d3d8;
  --ion-color-aqua-20-tint: #e1f2f6;

  --ion-color-base: var(--ion-color-aqua-20);
  --ion-color-base-rgb: var(--ion-color-aqua-20-rgb);
  --ion-color-contrast: var(--ion-color-aqua-20-contrast);
  --ion-color-contrast-rgb: var(--ion-color-aqua-20-contrast-rgb);
  --ion-color-shade: var(--ion-color-aqua-20-shade);
  --ion-color-tint: var(--ion-color-aqua-20-tint);
}

.ion-color-aqua-80 {
  --ion-color-aqua-80-rgb: 119, 193, 213;
  --ion-color-aqua-80-contrast: #000000;
  --ion-color-aqua-80-contrast-rgb: 0, 0, 0;
  --ion-color-aqua-80-shade: #69aabb;
  --ion-color-aqua-80-tint: #85c7d9;

  --ion-color-base: var(--ion-color-aqua-80);
  --ion-color-base-rgb: var(--ion-color-aqua-80-rgb);
  --ion-color-contrast: var(--ion-color-aqua-80-contrast);
  --ion-color-contrast-rgb: var(--ion-color-aqua-80-contrast-rgb);
  --ion-color-shade: var(--ion-color-aqua-80-shade);
  --ion-color-tint: var(--ion-color-aqua-80-tint);
}

.ion-color-peach {
  --ion-color-base: var(--ion-color-peach);
  --ion-color-base-rgb: var(--ion-color-peach-rgb);
  --ion-color-contrast: var(--ion-color-peach-contrast);
  --ion-color-contrast-rgb: var(--ion-color-peach-contrast-rgb);
  --ion-color-shade: var(--ion-color-peach-shade);
  --ion-color-tint: var(--ion-color-peach-tint);
}

.ion-color-peach-20 {
  @extend .ion-color-peach;
  --ion-color-base: var(--ion-color-peach-20);
}

.ion-color-cream {
  --ion-color-base: var(--ion-color-cream);
  --ion-color-base-rgb: var(--ion-color-cream-rgb);
  --ion-color-contrast: var(--ion-color-cream-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cream-contrast-rgb);
  --ion-color-shade: var(--ion-color-cream-shade);
  --ion-color-tint: var(--ion-color-cream-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-black-20 {
  --ion-color-black-20-rgb: 204, 204, 204;
  --ion-color-black-20-contrast: #000000;
  --ion-color-black-20-contrast-rgb: 0, 0, 0;
  --ion-color-black-20-shade: #b4b4b4;
  --ion-color-black-20-tint: #d1d1d1;

  --ion-color-base: var(--ion-color-black-20);
  --ion-color-base-rgb: var(--ion-color-black-20-rgb);
  --ion-color-contrast: var(--ion-color-black-20-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-20-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-20-shade);
  --ion-color-tint: var(--ion-color-black-20-tint);
}

.ion-color-black-50 {
  --ion-color-black-50-rgb: 128, 128, 128;
  --ion-color-black-50-contrast: #000000;
  --ion-color-black-50-contrast-rgb: 0, 0, 0;
  --ion-color-black-50-shade: #717171;
  --ion-color-black-50-tint: #8d8d8d;

  --ion-color-base: var(--ion-color-black-50);
  --ion-color-base-rgb: var(--ion-color-black-50-rgb);
  --ion-color-contrast: var(--ion-color-black-50-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-50-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-50-shade);
  --ion-color-tint: var(--ion-color-black-50-tint);
}

.ion-color-black-60 {
  --ion-color-black-60-rgb: 102, 102, 102;
  --ion-color-black-60-contrast: #ffffff;
  --ion-color-black-60-contrast-rgb: 255, 255, 255;
  --ion-color-black-60-shade: #5a5a5a;
  --ion-color-black-60-tint: #757575;

  --ion-color-base: var(--ion-color-black-60);
  --ion-color-base-rgb: var(--ion-color-black-60-rgb);
  --ion-color-contrast: var(--ion-color-black-60-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-60-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-60-shade);
  --ion-color-tint: var(--ion-color-black-60-tint);
}

.ion-color-black-80 {
  --ion-color-black-80-rgb: 51, 51, 51;
  --ion-color-black-80-contrast: #ffffff;
  --ion-color-black-80-contrast-rgb: 255, 255, 255;
  --ion-color-black-80-shade: #2d2d2d;
  --ion-color-black-80-tint: #474747;

  --ion-color-base: var(--ion-color-black-80);
  --ion-color-base-rgb: var(--ion-color-black-80-rgb);
  --ion-color-contrast: var(--ion-color-black-80-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-80-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-80-shade);
  --ion-color-tint: var(--ion-color-black-80-tint);
}

/* Importing Bootstrap SCSS file. */
@import './bootstrap/scss/bootstrap';

