/**
 * helpers includes generic helpers for global use
 */

/**
 * display mixin
 */
@mixin generateDisplay() {
  $displays: [flex, grid, inline-block, block, inline, inline-flex];

  @for $i from 1 through length($displays) {
    $display: nth($displays, $i);

    .d-#{$display} {
      display: $display !important;
    }
  }
}

@include generateDisplay();

@mixin generateFlexDirection() {
  $directions: [column, column-reverse, row, row-reverse, inherit, initial, unset];

  @for $i from 1 through length($directions) {
    $direction: nth($directions, $i);

    .flex-#{$direction} {
      flex-direction: $direction !important;
    }
  }
}

@include generateFlexDirection();

@mixin generateFlexWrap() {
  $wraps: [nowrap, revert, wrap, wrap-reverse, unset];

  @for $i from 1 through length($wraps) {
    $wrap: nth($wraps, $i);

    .flex-#{$wrap} {
      flex-wrap: $wrap !important;
    }
  }
}

@include generateFlexWrap();

@mixin generateFlexGrow() {
  $grows: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  @for $i from 1 through length($grows) {
    $grow: nth($grows, $i);

    .flex-grow-#{$grow} {
      flex-grow: $grow !important;
    }
  }
}

@include generateFlexGrow();

@mixin generateFlex() {
  $grows: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  @for $i from 1 through length($grows) {
    $grow: nth($grows, $i);

    .flex-#{$grow} {
      flex: $grow !important;
    }
  }
}

@include generateFlex();

/**
 * font mixin
 */

@mixin fontWeight() {
  $weights: [100, 200, 300, 400, 500, 600, 700, 800, 900];

  @for $i from 1 through length($weights) {
    $weight: nth($weights, $i);

    .fw-#{$weight} {
      font-weight: $weight !important;
    }
  }
}

@include fontWeight();

@mixin width() {
  $widthPercents: [0%, 10%, 20%, 30%, 40%, 50%, 60%, 80%, 90%, 100%];
  $widths: [0, 10, 20, 30, 40, 50, 60, 80, 90, 100];

  @for $i from 1 through length($widths) {
    $width: nth($widths, $i);
    $widthPercent: nth($widthPercents, $i);

    .w-#{$width} {
      width: $widthPercent !important;
    }
  }
}

@include width();
