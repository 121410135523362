// Common Ionic elements styles

* {
  font-family: var(--ion-theme-font);
}

ion-button {
  text-transform: none;
  &.min-width-350{
    min-width: 350px ;
  }
  &.common {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0.005em;
    height: 2.75rem;
    --border-radius: 8px !important;
    --box-shadow: none;

    &_small {
      font-size: 14px;
      height: 32px;
      --padding-start: var(--ion-offset);
      --padding-end: var(--ion-offset);
    }

    &_rectangle {
      --border-radius: 6px;
    }

    &[fill='outline'] {
      --border-width: 1px;
    }

    &[fill='solid'] {
      --padding-end: 18px;
      --padding-start: 18px;
    }
  }
}

.rectangle {
  height: 1px;
  background: linear-gradient(90deg, rgb(55, 89, 124) 8.04%, rgb(85, 178, 203) 49.18%, rgb(255, 174, 136) 89.29%);
}

.rectangle5 {
  height: 5px;
  background: linear-gradient(90deg, rgb(55, 89, 124) 8.04%, rgb(85, 178, 203) 49.18%, rgb(255, 174, 136) 89.29%);
}

.showAll {
  max-height: fit-content !important;
  overflow: visible !important;
}

.pink-gradient-section{
  min-height: 18rem;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(251, 162, 133, 0.25) 32.98%, rgba(255, 255, 255, 0.25) 100%);
}
